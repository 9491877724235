//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    includeFixed: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      env: "en",
      showFixed: false,
      is_auth_modal: false,
      isLogin: false,
      headerLocalFormType: "signup",
    };
  },
  methods: {
    authSuccess() {
      window.location = "/account";
    },
    handleDashboard() {
      if (this.isAuthenticated) {
        window.location.href = "/account";
        // this.$router.push("/account");
      } else {
        this.headerLocalFormType = "login";
        this.showAuthModal();
      }
    },
    visibilityChanged(isVisible, entry) {
      this.showFixed = !isVisible;
    },
    showAuthModal() {
      this.is_auth_modal = true;
    },
    hideAuthModal() {
      this.is_auth_modal = false;
    },
    // showLogIn() {
    //   this.headerLocalFormType = "login";
    //   this.showAuthModal();
    // },
    // showSignUp() {
    //   this.headerLocalFormType = "signup";
    //   this.showAuthModal();
    // },
  },
  watch: {
    isAuthenticated(newValue) {
      if (newValue === false) if (this.showSignUp) this.showAuthModal();
    },
  },
  computed: {
    isProfileLoaded() {
      return this.loadRequest ? this.loadRequest.isLoaded : false;
    },
    loadRequest() {
      return this.$store
        .$db()
        .model("requests")
        .find("profile");
    },
    showSignUp() {
      if (this.$route.query.signup) return true;
    },
    shouldLoadUser() {
      return this.$store.state.user.loading;
    },
    isAuthenticated() {
      return this.$store.state.user.data.registered;
    },
  },
  mounted() {
    if (process.client && this.shouldLoadUser) {
      this.$store.dispatch("user/getUser");
    }
    // if (this.isProfileLoaded && !this.isAuthenticated)
    //   this.is_auth_modal = true;
  },
};
