import { render, staticRenderFns } from "./signup-form.vue?vue&type=template&id=2acc8c8e&"
import script from "./signup-form.vue?vue&type=script&lang=js&"
export * from "./signup-form.vue?vue&type=script&lang=js&"
import style0 from "./signup-form.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LnCustomButton: require('/usr/src/app/components/ln-custom-button.vue').default,LnForm: require('/usr/src/app/components/ln-form/ln-form.vue').default})
