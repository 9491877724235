var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ln-form-addon-radio",on:{"click":function($event){return _vm.categoryChecked()}}},[_c('span',{staticClass:"fake-checkmark",class:_vm.checkFields ? 'checked-all' : ''}),_vm._v(" "),_c('div',{staticClass:"ln-form-addon-radio__info bv-no-focus-ring",class:_vm.getValidationState(_vm.validationContext) == false ? 'is-invalid' : '',attrs:{"id":("field-" + (_vm.field.name) + "-" + _vm.fieldIndex),"role":"radiogroup","tabindex":"-1"}},[_c('h3',[_vm._v(_vm._s(_vm.field.label))]),_vm._v(" "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.addonDescription)}}),_vm._v(" "),_c('div',{staticClass:"flex"},[(_vm.field.price)?_c('p',{staticClass:"ln-form-addon-radio__price"},[_vm._v("\n        "+_vm._s(_vm.field.price)),(_vm.field.unit)?_c('span',[_vm._v(" "+_vm._s(_vm.field.unit)+" ")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.field.helper_text.length > 60)?_c('p',{staticClass:"ln-form-addon-radio__showmore tw-flex tw-items-center",class:_vm.showDescription ? 'reverse-icon' : '',on:{"click":function($event){$event.stopPropagation();_vm.showDescription = !_vm.showDescription}}},[_vm._v("\n        "+_vm._s(_vm.showDescription ? "Hide" : "Show")+" details\n        "),_c('icon-chevron',{staticClass:"tw-ml-1"})],1):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"ln-form-addon-radio__img",class:_vm.field.options ? 'ln-form-addon-radio__img--top' : ''},[_c('img',{attrs:{"src":_vm.illustrations[_vm.field.illustration],"alt":"document icon"}})]),_vm._v(" "),(_vm.field.options)?_c('div',{staticClass:"ln-form-addon-radio__options"},_vm._l((_vm.field.options),function(option,index){return _c('div',{key:index,class:_vm.radioClass(
          option,
          index,
          _vm.validationContext,
          _vm.localValue === option.value
        ),on:{"click":function($event){_vm.checkFields = true}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"custom-control-input",attrs:{"id":("field-" + (_vm.field.name) + "_BV_option_" + index + "-" + _vm.fieldIndex),"type":"radio","name":((_vm.field.name) + "-" + _vm.fieldIndex),"autocomplete":"off"},domProps:{"value":option.value,"checked":_vm._q(_vm.localValue,option.value)},on:{"change":function($event){_vm.localValue=option.value}}}),_c('label',{staticClass:"custom-control-label",class:[
          option.isFullWidth && option.image ? 'isFullWidthImage' : '',
          option.tag && !option.price ? 'hasLabelTag' : '' ],attrs:{"for":("field-" + (_vm.field.name) + "_BV_option_" + index + "-" + _vm.fieldIndex)}},[_c('p',{staticClass:"ln-radio-custom-label-p"},[_c('span',{domProps:{"innerHTML":_vm._s(option.label)}})]),_vm._v(" "),(option.price)?_c('p',{staticClass:"ln-radio-custom-label-p"},[_c('span',[_vm._v("$"+_vm._s(option.price))])]):_vm._e()])])}),0):_vm._e(),_vm._v(" "),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.getValidationState(_vm.validationContext) === false),expression:"getValidationState(validationContext) === false"}],staticClass:"tw-text-coral tw-mt-2",attrs:{"id":("field-" + (_vm.field.name) + "-feedback")}},[_vm._v("\n    This field is required\n  ")])])}
var staticRenderFns = []

export { render, staticRenderFns }